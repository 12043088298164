import React, { FC } from "react";
import {Articlex3Wrapper, Wrapper} from "./HeroPlus3.styled";
import Hero from "Components/Hero";
import Item from "Components/Item";
import { SectionComponent } from "../types";
import {getCropData} from "../getCropData";

interface Article {
  hero?: Array<{
    data?: {
      extra?: {
        sizes?: {
          [key: string]: any;
        };
      };
    };
  }>;
}

const HeroPlus3: FC<SectionComponent> = ({ data }) => {
  const [mainArticle, ...articles] = data.articles;

  return (
    <Wrapper>
      <Hero size={3} article={mainArticle} cropData={getCropData(mainArticle, "onebyone")} />
      <Articlex3Wrapper>
        {articles.map((article, index) => (
          <Item
            key={index}
            size={3}
            article={article}
            cropData={getCropData(article, "onebyone")}
          />
        ))}
      </Articlex3Wrapper>
    </Wrapper>
  );
};

export default HeroPlus3;
