type Image = {
  type: string;
  data?: {
    extra?: {
      sizes?: {
        [key: string]: any;
      };
    };
  };
};

type Article = {
  hero?: Image[];
};

export const getCropData = (article: Article, size: string): any => {
  // Find the first hero image with the type 'image'
  const firstImage = article?.hero?.find(({ type }) => type === 'image');

  // Return the crop data for the specified size, or undefined if not found
  const crop = firstImage?.data?.extra?.sizes?.[size];

  return crop || '';
};
