import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 16px;
  padding-bottom: 31px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
`;
export const Articlex3Wrapper = styled.div`
  display: grid;
  row-gap: 16px;
`;
